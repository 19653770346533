import React, { useEffect, useState } from "react";
import "./App.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement("#root");

function Navbar() {
  return (
    <nav className="navbar p-4 md:px-32 flex flex-col md:flex-row justify-between items-center top-0 w-full z-50">
      <h1 className="text-4xl text-primary font-extrabold bg-transparent text-center p-4" style={{ textShadow: "0 0 80px #ff1cec" }}>
        Plasma
      </h1>
      <div className="flex space-x-8">
        <a href="#" className="text-white ">
          Home
        </a>
        <a href="#products" className="text-white">
          Products
        </a>
        <a href="https://discord.gg/plasmafn" className="text-white">
          Discord
        </a>
      </div>
    </nav>
  );
}

function App() {
  const reasons = [
    { text: "High Quality", description: "We ensure the highest quality for our products." },
    { text: "Affordable Prices", description: "Our prices are competitive and fair." },
    { text: "Great Customer Service", description: "We strive to provide excellent service to all our customers." },
    { text: "Fast Delivery", description: "We guarantee fast and reliable delivery for all orders." },
    { text: "Secure Payment", description: "Our payment methods are secure and trustworthy." },
    { text: "Easy Returns", description: "We offer easy returns if you're not satisfied with your purchase." },
  ];

  const products = [
    {
      title: "Temp Spoofer",
      features: "Temporarily spoofs PC\n Cheapest on the Market\n Fully Undetected on BE/EAC\n Windows 10 Supported\n Tournament Ready\n Good Support Team",
      price: "6.99$",
      seo_path: "65a8771132935",
    },
    {
      title: "Perm Spoofer",
      features: "Permanently spoofs PC\n Fully Unbans your PC\n Cheapest on the Market\n Fully Undetected on BE/EAC\n Windows 10 Supported\n Tournament Ready\n Good Support Team",
      price: "19.99$",
      seo_path: "65a88e080fa9e",
    },
    {
      title: "Fortnite Public",
      features: "Public Fortnite Cheat\n Competitive pricing\n BattleEye/Easy Anti-Cheat (BE/EAC) undetected\n Windows 10 Supported\n Suitable for tournaments\n Reliable Support Team",
      price: "6.99",
      seo_path: "65a890c740404",
    },
    {
      title: "Fortnite FA's",
      features: "Cheapest on the market\n Free Replacements\n Stacked Accounts \n Ready to play\n Tailored for competitive play, Dedicated Support Team",
      price: "5.50$",
      seo_path: "66406c38b684d",
    },
    {
      title: "Fresh Battle.Nets",
      features: "Freshly created Battle.Net accounts\n Affordable pricing\n Ensured security\n Compatible with MW3\n Ideal for new starts\n Responsive Support Team",
      price: "0.75$",
      seo_path: "663d7a7a3038b",
    },
    {
      title: "Camo Services",
      features: "Cheap Camos\n Best rates\n Customizable options\n Fully Undetected\n Fast and effective\n Support available",
      price: "10.00$",
      seo_path: "663d7a275cff0",
    },
    {
      title: "MW3 Silent-Chair",
      features: "MW3 Cheat\n Best Price\n Ricochet Undetected\n Windows 10 Supported\n Cheapest and Reliable \n Reliable Support Team",
      price: "5.99$",
      seo_path: "65cd292c99f14",
    },
    {
      title: "MW3 Unlock-All",
      features: "Call of Duty: Modern Warfare 3 unlock all tool\n Unlocking of all in-game content\n Affordable price\n Safe and secure service\n Your favorite skins and items\n Fast Support",
      price: "3.99$",
      seo_path: "65adf1054bea8",
    },
    {
      title: "Valorant Unlock-All",
      features: "Valorant Unlock All tool\n Best Price\n Windows 10 Supported\n Unlock all in-game content\nCheapest and Reliable \n Reliable Support Team",
      price: "4.99$",
      seo_path: "664bb3155e15f",
    },
    {
      title: "Valorant Full",
      features: "Valorant Cheat\n Best Price\n Windows 10 Supported\n Cheapest and Reliable \n Reliable Support Team",
      price: "6.99$",
      seo_path: "664bb1d930519",
    },
  ];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("Status goes here");

  function openModal() {
    setIsOpen(true);
  }

  const [isClosing, setIsClosing] = useState(false);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setIsOpen(false);
    }, 200);
  };

  useEffect(() => {
    toast("Use code ”Plasma” at checkout for 20% off", {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
      theme: "dark",
    });
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%", // Adjust the width
      height: "70%", // Adjust the height
      backgroundColor: "#0f0f0f",
      border: "3px solid #ff1cec",
      borderRadius: "20px",
      animation: "appear 0.3s ease-in-out", // Add this line for the popup animation
      animation: isClosing ? "disappear 0.3s ease-in-out" : "appear 0.3s ease-in-out",
    },
    overlay: {
      zIndex: 1000, // This will ensure the modal is on top of everything
      backgroundColor: "rgba(0, 0, 0, 0)", // This will remove the background opacity
    },
  };

  return (
    <div className="min-h-screen bg-bg">
      <ToastContainer />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Status Modal">
        <div className="flex flex-col bg-bg mx-4 p-4 items-center w-auto h-auto overflow-x-hidden">
          <h1 className="text-white text-2xl font-normal mb-2">All Systems online</h1>
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col justify-between items-start">
              <div className="flex flex-col mb-2 w-full">
                <h1 className="text-white text-lg font-light mt-2">Fortnite Public</h1>
                <h1 className="absolute right-20 text-white text-lg font-light mt-2">100.0% Uptime</h1>
                <div className="bg-primary w-[40vw] rounded-md flex">
                  {Array.from({ length: 20 }, (_, i) => (
                    <div key={i} className="flex-grow h-4 bg-primary rounded-xl mx-4 border-r border-black last:border-r-0"></div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <h1 className="text-white text-lg font-light mt-4">MW3 Silent Chair</h1>
                <h1 className="absolute right-20 text-white text-lg font-light mt-2">100.0% Uptime</h1>
                <div className="bg-primary w-[40vw] rounded-md flex">
                  {Array.from({ length: 20 }, (_, i) => (
                    <div key={i} className="flex-grow h-4 bg-primary rounded-xl mx-4 border-r border-black last:border-r-0"></div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <h1 className="text-white text-lg font-light mt-4">Perm Spoofer</h1>
                <h1 className="absolute right-20 text-white text-lg font-light mt-2">100.0% Uptime</h1>
                <div className="bg-primary  w-[40vw] rounded-md flex">
                  {Array.from({ length: 20 }, (_, i) => (
                    <div key={i} className="flex-grow h-4 bg-primary rounded-xl mx-4 border-r border-black last:border-r-0"></div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <h1 className="text-white text-lg font-light mt-4">Temp Spoofer</h1>
                <h1 className="absolute right-20 text-white text-lg font-light mt-2">100.0% Uptime</h1>
                <div className="bg-primary w-[40vw] rounded-md flex">
                  {Array.from({ length: 20 }, (_, i) => (
                    <div key={i} className="flex-grow h-4 bg-primary rounded-xl mx-4 border-r border-black last:border-r-0"></div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <h1 className="text-white text-lg font-light mt-4">Camo Services</h1>
                <h1 className="absolute right-20 text-white text-lg font-light mt-2">100.0% Uptime</h1>
                <div className="bg-primary  w-[40vw] rounded-md flex">
                  {Array.from({ length: 20 }, (_, i) => (
                    <div key={i} className="flex-grow h-4 bg-primary rounded-xl mx-4 border-r border-black last:border-r-0"></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="hero border-b-4 border-primary">
        <Navbar />
        <div className="container mx-auto pb-10 p-6 grid grid-cols-1 md:grid-cols-2 gap-4 h-screen flex justify-between items-center">
          {/* First column */}
          <div className="col-span-1">
            <div className="w-min text-nowrap h-min pb-4">
              <p className="text-white text-sm p-[0.3rem] px-3 bg-bg border border-pink-500 rounded-md">Plasma - Shop #1 in the Cheat Market</p>
            </div>
            <div>
              <h1 className="md:text-7xl font-medium text-5xl mb-4 bg-gradient-to-t from-white to-gray-400 text-transparent bg-clip-text">
                ENHANCE YOUR <br />
              </h1>
              <h1 className="md:text-7xl font-medium mb-4">
                <span className="md:text-7xl font-medium mb-4 text-5xl text-primary">GAMING EXPERIENCE</span>
              </h1>

              <p className="text-base text-gray-500 mb-8 font-light">We provide the opportunity to purchase only the best products. By choosing us, you choose quality!</p>
            </div>
            <div className="flex space-x-4">
              <button className="btn">
                <a href="#products">Products</a>
              </button>
              <button className="bg-bg text-white font-normal py-1 px-5 rounded-lg text-sm border border-neutral-800" onClick={openModal}>
                Status
              </button>
            </div>
          </div>

          {/* Second column */}
          <div className="hidden md:flex justify-end col-span-1">
            <div className="w-[23vw] h-[40vh] border rounded-xl border-neutral-700 bg-neutral-950 relative overflow-hidden">
              <div className="flex justify-center items-center h-[70%]">
                <h1 className="text-5xl text-primary font-extrabold bg-transparent text-center p-4" style={{ textShadow: "0 0 80px #ff1cec" }}>
                  Plasma
                </h1>
              </div>
              <h1 className="text-md text-white font-light px-4">
                <span className="font-bold">Popular</span> Products
              </h1>
              <div className="flex justify-center items-center h-[30%] ml-8 pb-8">
                <Swiper spaceBetween={50} slidesPerView={3} loop={true} direction={"horizontal"} modules={[Autoplay]} autoplay={{ delay: 0 }} speed={1500}>
                  {" "}
                  {/* while modal is on dont do swiper */}
                  {!modalIsOpen && (
                    <>
                      <SwiperSlide>
                        <img src="https://i.imgur.com/szQHo3Z.png" alt="slider" className="w-8 h-8 animate-slide filter brightness-0" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src="https://i.imgur.com/QThxcqR.png" alt="slider" className="w-8 h-8 animate-slide filter brightness-0" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src="https://i.imgur.com/LY58qlF.png" alt="slider" className="w-8 h-8 animate-slide filter brightness-0" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src="https://i.imgur.com/adbFLcK.png" alt="slider" className="w-8 h-8 animate-slide filter brightness-0" />
                      </SwiperSlide>
                    </>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-1 mx-auto pb-[10rem] p-6 flex justify-center">
        <div className="flex flex-col items-center" id="products">
          <h1 className="text-4xl font-medium bg-gradient-to-t from-white to-gray-400 text-transparent bg-clip-text">
            Best Sellers <br />
          </h1>

          <p className="text-base text-neutral-500 font-light">Our most purchased products.</p>
        </div>
      </div>
      <div className="container-2 pb-10 grid grid-cols-1 md:grid-cols-3 justify-center gap-x-0 mb-16">
        <div className="swiper-container" style={{ minWidth: "100vw", maxWidth: "1200px" }}>
          <Swiper
            slidesPerView={3}
            loop={true}
            modules={[Autoplay]}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              640: {
                slidesPerView: 3,
              },
              0: {
                slidesPerView: 1,
              },
            }}
          >
            {products.map((product, index) => (
              <SwiperSlide key={index}>
                <Product title={product.title} features={product.features} price={product.price} seo_path={product.seo_path} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="container mx-auto pb-[10rem] p-6">
        <div className="flex flex-col items-center justify-center ">
          <h1 className="text-4xl font-medium bg-gradient-to-t from-white to-gray-400 text-transparent bg-clip-text mb-4">Why Us</h1>
          <p className="text-base text-neutral-500 font-light px-4 text-center">We provide the opportunity to purchase only the best products. By choosing us, you choose quality!</p>
        </div>
        <div className="grid md:grid-cols-3 gap-16 mt-8">
          {reasons.map((reason, index) => (
            <div key={index} className="flex space-x-4 border-2 border-primary rounded-xl p-4 text-white shadow-lg shadow-primary mb-2">
              <div>
                <h1 className="text-4xl text-primary font-bold">0{index + 1}</h1>
                <h1 className="text-2xl font-bold">{reason.text}</h1>
                <p className="text-sm text-neutral-600 py-3">{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <footer className="text-start text-white py-4 px-4 text-sm hover:text-primary transition-[2s]">
        <a href="https://discord.gg/HRRK9BPCCx"> Made by Osman.38 | © OsmanliHub</a>
      </footer>
    </div>
  );
}

function Product({ title, features, price, seo_path, main }) {
  features = features?.split("\n").map((feature, index) => (
    <p key={index} className="text-white text-sm font-light mt-3 shadow-xl">
      {feature}
    </p>
  ));

  title = title.split(" ");
  title[1] = <span style={{ color: "#fc03d7" }}> {title[1]}</span>;

  const handleClick = () => {
    const url = `https://plasmafn.sellpass.io/embed/products/${seo_path}`;
    const windowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=400,height=1000";
    window.open(url, "_blank", windowFeatures);
  };

  return (
    <div id="product" className={"product bg-neutral-900 w-full md:w-96 rounded-xl flex flex-col items-center py-4 mx-auto shadow-md shadow-primary mb-2 h-auto"} style={main ? { backgroundColor: "#0f0f0f", border: "1px solid #ff1cec" } : { marginTop: "30px" }}>
      <h1 className="md:text-4xl text-3xl font-bold text-white pt-4">{title}</h1>
      <p className="text-neutral-500 font-normal pt-4">Starting at:</p>
      <p className="text-3xl font-bold text-primary">{price}</p>
      <div className="w-[90%] border border-primary mt-4 mx-4 mb-4 relative">
        <span className="text-primary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-neutral-900 px-2" style={main ? { backgroundColor: "#0f0f0f" } : {}}>
          Features
        </span>
      </div>
      {features}
      <div className="flex flex-col justify-end mb-2 items-center h-full w-11/12">
        <button id="purchase-btn" data-sellix-product={seo_path} type="submit" className="btn mt-8 mx-4 w-[80%]">
          <a>Purchase</a>
        </button>
      </div>
    </div>
  );
}

export default App;
